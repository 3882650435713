<template>
  <v-row>
 
    <div class="w-100" v-if="showSuccess">
      <SuccessMessagePanel>
        <p>
          <b>Confirm:{{ removedContactCount }}</b> Contacts Added.
        </p>
      </SuccessMessagePanel>
    </div> 
    
    <S2gTable
      :indeterminate="indeterminate"
      :items="contacts"
      :headers="[{ key: null, title: null }]"
      :filterItems="[
        { label: 'All', value: 'all' },
        { label: 'None', value: 'none' },
      ]"
      @column-filter="handleFilter"
      @toggle-row-select="selectMembers"
      
      @search="searchMember"
      :isReload="false"
      :isSelectGlobalCheckBox="isSelectAll"
      :totalItems="`${contactPage.pager.totalItems}`"
      :totalCountText="'Contacts'"
      :totalCountText1="'Contact'"
      :showCount="true"
      @close-search="onCloseSearch"
      @open-search="onOpenSearch"
      @select-all="selectAllContacts"
      :page="(contactPage.currentPage - 1) * contactPage.itemsPerPage"
    >
      <template v-slot:table_body="{ item }">
        <td>
          <div class="d-flex justify-space-between">
            <span
              >{{ item.item.FirstName }}
              {{ item.item.LastName }} 
              <!-- || {{ item.item.UserID }} -->
            </span>
          </div>
        </td>
      </template>
      
    </S2gTable>
    <v-row class="mt-0">
      <v-col cols="12">
        <div class="pagination">
          <Paginaiton
            v-if="contactPage.pager.totalPages > 1"
            :currentPage="contactPage.currentPage"
            :totalItems="contactPage.pager.totalItems"
            :itemsPerPage="contactPage.itemsPerPage"
            :totalPages="contactPage.pager.totalPages"
            @page-changed="changePage"
          />
        </div>
      </v-col>
      <!-- <v-col class="d-flex justify-end" cols="12" sm="6" md="6">
        <button type="button"
          class="btn-iconed"
          :class="{
            'btn-iconed-disabled': !activateGroupBtn,
          }"
          @click="addContactsToGroupFn"
        >
          Add to group
          <ArrowInsideButton />
        </button>
      </v-col> -->
    </v-row>
  </v-row>
</template>

<script>
const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
import S2gTable from "@/components/common/S2gTable.vue";
import SuccessMessagePanel from "@/components/common/SuccessMessagePanel.vue";
import Paginaiton from "../../components/common/Paginaiton.vue";
//import ArrowInsideButton from "@/components/icons/ArrowInsideButton.vue";

import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
} from "@/helpers/s2gtable-helper";
export default {
  components: {
    S2gTable,
    Paginaiton,
    SuccessMessagePanel,
    //ArrowInsideButton
  },
  props: {
    group: {
      default: () => {
        return {};
      },
      type: Object,
    },
    selectedMember: {
      default: () => {
        return {};
      },
      type: Object,
    },
    isSelectAllContacts:{
      default:() =>{
        return false;
      },
      type: Boolean,
    },
    tempGroupData:{
      default: () => {
        return {};
      },
      type: Object,
    }
  },

  data: () => ({
    dialog: false,
    loading: false,
    isSelectAll: false,
    showSuccess: false,
    selectedMembers: 0,
    indeterminate: false,
    activateGroupBtn: false,
    contacts: [],
    tab: null,
    removedContactCount: 0,
    contactPage: {
      pager: { totalPages: 0, totalItems: 0 },
      currentPage: 1,
      itemsPerPage: 10,
    },
    token: localStorage.getItem("tknds") || "",
    selectedContacts: 0,
    showConfirm: false,
    searchMemberText: "",
    selectedContactIds: [],
    uncheckedMembers: [],
    checkedMember: [],
    activateAddGroup: false,
    sort: "FirstName",
    order: "asc",
    allgroupmembers:[],
    allConctactIds:[],
  }),
  watch: {
    allgroupmembers: function (newVal, oldVal) {
      //to be added Add the disabled allgroupmembers with uncheckedContacts
      let uncheckedContactsIds = this.uncheckedMembers.map(a=> a.UserID);
      uncheckedContactsIds = uncheckedContactsIds.concat(this.allgroupmembers);
      this.$emit("contact-selected", {checkedContacts: this.checkedMember, isSelectAll:this.isSelectAll, uncheckedContacts:uncheckedContactsIds, selectedItem:this.selectedContacts });
    },
    selectedContacts: function (newVal, oldVal) {
      //to be added Add the disabled allgroupmembers with uncheckedContacts
      let uncheckedContactsIds = this.uncheckedMembers.map(a=> a.UserID);
      uncheckedContactsIds = uncheckedContactsIds.concat(this.allgroupmembers);
      this.$emit("contact-selected", {checkedContacts: this.checkedMember, isSelectAll:this.isSelectAll, uncheckedContacts:uncheckedContactsIds, selectedItem:this.selectedContacts });
    },
    isSelectAll: function (newVal, oldVal) {
      //to be added Add the disabled allgroupmembers with uncheckedContacts
      let uncheckedContactsIds = this.uncheckedMembers.map(a=> a.UserID);
      uncheckedContactsIds = uncheckedContactsIds.concat(this.allgroupmembers);
      //alert(uncheckedContactsIds.concat(this.allgroupmembers).join(','))
      this.$emit("contact-selected", {checkedContacts: this.checkedMember, isSelectAll:this.isSelectAll, uncheckedContacts:uncheckedContactsIds, selectedItem:this.selectedContacts});
    },
    tempGroupData: function(newVal, oldVal){
      this.getGroupsAll();
    }
  
  },
  methods: {
    handleFilter(e) {
      const event = e.e;
      if (event.value == "all") {
        this.selectAllContacts({ target: { checked: true } });
      } else {
        this.selectAllContacts({ target: { checked: false } });
      }
    },
    onCloseSearch(){
      this.$emit("search-open", false);
    },
    onOpenSearch(){
      this.$emit("search-open", true);
    },
    getContacts() {
      let url =
        API_URL +
        "teams/?page=" +
        this.contactPage.currentPage +
        "&perpage=" +
        this.contactPage.itemsPerPage +
        "&sort=" +  this.sort +
        "&search=" +
        this.searchMemberText;
        url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
        url += "&Role=" + this.$root.userdetail.user[0].Role;
        url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
        //url += "&group_id=" + this.group.Id;
        //url += "&sort=" + this.sort;
        url += "&order=" + this.order;
        //url += "&NotUserID=" + this.allgroupmembers;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            //console.log('here are the contacts :: ', result)
            this.allConctactIds = result.teams.alluserids;
            this.contacts = result.teams.docs?.map((e) => {
              //console.log('here contacts', e)
              e.checked =
                this.isSelectAll &&
                this.allgroupmembers.indexOf(e.UserID) ==
                  -1;
              
            /*   e.checked =
                this.isSelectAll &&
                this.uncheckedMembers.map((e) => e.UserID).indexOf(e.UserID) ==
                  -1; */
              e.class = "hover_item";
              e.checkdClass = "selected_item";
              if (this.checkedMember.some((u) => u.UserID == e.UserID)) {
                e.checked = true;
              }/* 
              if (!this.activateGroupBtn && e.checked && !this.selectedMember?.checkedMember?.some((u) => u.UserID == e.UserID) && !this.selectedMember?.isSelectAll) {
                console.log('----', e,this.selectedMember?.checkedMember)
                e.class = "disabled";
              } */
              if(this.allgroupmembers.indexOf(e.UserID) != -1){
                e.disable_checkbox = true;
              }
              /* if (!this.manageMemberSelect(e)) {
                //console.log("3",e.FirstName, this.manageMemberSelect(e));
                e.disable_checkbox = true;
                if(!this.isSelectAll){
                  e.checked = false;
                }
              } else {
                //console.log( e.UserID, this.selectedMember?.uncheckedMember )
              } */
              return e;
            });

            //console.log("this.contacts=" , this.contacts);
            this.contactPage.pager = result.teams.pager;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateSelectMembers(e) {
      //console.log("fd");
    },
    cancel() {
      //console.log("ddd no");
    },

    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    reset() {
      this.loading = false;
      this.isSelectAll = false;
      this.indeterminate = false;
      this.selectedContacts = 0;
      this.selectedContactIds = [];
      this.uncheckedMembers = [];
      this.checkedMember = [];
    },
    
    selectMembers(e) {
      try {
        let event = e.e;
        const u = manageSingleChecked(
          event,
          e.item,
          "UserID",
          this.contacts,
          this.selectedContacts,
          this.isSelectAll,
          this.contactPage.pager.totalItems
        );
        this.selectedContacts = u.selectedCounts;
        this.indeterminate = u.indeterminate;
        this.isSelectAll = u.isSelectAll;
        if (this.selectedContacts > 0) {
          this.activateGroupBtn = true;
        } else {
          this.activateGroupBtn = false;
        }
        //console.log(this.contacts);
        manageSelectedItems(event, e.item, "UserID", this.checkedMember);
        manageUnSelectedItems(event, e.item, "UserID", this.uncheckedMembers);
        //alert(this.checkedMember.length)
        //this.$emit("contact-selected", {checkedContacts: this.checkedMember, isSelectAll:this.isSelectAll, uncheckedContacts:this.uncheckedMembers});
        
        console.log("pppp", this.selectedContactIds);
      } catch (e) {
        console.log(e);
      }
    },
    selectAllContacts(e) {
      this.isSelectAll = e.target.checked;
      this.indeterminate = false;
      this.checkedMember = [];
      this.uncheckedMembers = [];
      if (!e.target.checked) {
        this.selectedContactIds = [];
        this.selectedContacts = 0;
        this.activateGroupBtn = false;
        this.contacts = this.contacts.map((e) => {
          e.checked = false;
          e.checkdClass = "";
          e.class = "";
          return e;
        });
      } else {
        this.activateGroupBtn = true;
        this.selectedContacts = this.contactPage.pager.totalItems - this.allgroupmembers.length;
        //alert(this.allgroupmembers.length)
        
        //alert(this.selectedContacts)
        this.contacts = this.contacts.map((e) => {
          e.checked = this.allgroupmembers.indexOf(e.UserID) == -1;
          return e;
        });
        this.selectedContactIds = this.contacts.map((r) => r.id);
      }
    },
    searchMember(val) {
      this.searchMemberText = val;
      this.getContacts();
    },
    changePage(page) {
      this.contactPage.currentPage = page;
      this.getContacts();
    },
    getGroups() {
      let url =
        API_URL +
        "groups/?page=1&perpage=10000"
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            let self = this;
            let selectedGroupIds = result.data.filter(function (val) {
                if(self.tempGroupData.uncheckedItems.map(a=> a.Id).indexOf(val.Id) == -1 && val.Status == "active"){
                  return val;
                }
            })
            this.getGroupMembers(selectedGroupIds.map(a=> a.Id));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getGroupMembers(grupids){
      let url = API_URL + "getgroupmembersmultiple/?groupids="+grupids.join(',');
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            let allGroupContacts = result.groupmembers.map(a=> a.Contact_Id);
            //alert(allGroupContacts.join(','))
            //Unique items
            this.allgroupmembers = [...new Set(allGroupContacts)];
            this.getContacts();
            //Remove the selected items if they are in selected group memebers;
            if (!this.isSelectAll){
              
              this.checkedMember = this.checkedMember.filter((e)=>{
                if(this.allgroupmembers.indexOf(e.UserID) == -1){
                  return e;
                }
              })
              this.selectedContacts = this.checkedMember.length;
            } else{
              //alert('check again')
              //alert(this.allConctactIds.join(',') + " || "+ this.allgroupmembers.join(','))
              this.selectedContacts = this.allConctactIds.filter((e)=>{
                if(!this.allgroupmembers.includes(Number(e))){
                  return e;
                }
              }).length;
             // alert(selectedContacts_temp.join(','))
              

              //alert(this.contactPage.pager.totalItems)
             /*  alert(allgroupmembers.join(','))
              this.selectedContacts = this.contacts.filter((e)=>{
                if(this.allgroupmembers.indexOf(e.UserID) == -1){
                  return e;
                }
              }).length; */
              //alert('select all')
            }
            //alert(this.selectedContacts +  ' CC')
            
            /* let self = this;
            let selectedGroupIds = result.data.filter(function (val) {
                if(self.tempGroupData.uncheckedItems.map(a=> a.Id).indexOf(val.Id) == -1){
                  return val;
                };
            })
            this.getGroupMembers(selectedGroupIds.map(a=> a.Id)); */
          }
        })
        .catch((e) => {
          console.log(e);
        });
      //("get these group members" + grupids.join(','))
    },
    getGroupsAll(){
      //alert(this.checkedMember.join(','))
      //Directly load contacts if no group is selected
      this.allgroupmembers = [];
      if (!this.tempGroupData.isSelectAll && this.tempGroupData?.checkedItems?.length == 0){
        if (this.isSelectAll){
          this.selectedContacts = this.allConctactIds.filter((e)=>{
              if(!this.allgroupmembers.includes(Number(e))){
                return e;
              }
            }).length;
        }
        this.getContacts();
        return;
      }
      let selectedGroupIds = [];
      if(this.tempGroupData.isSelectAll){
        this.getGroups();
      } else {
        if(this.tempGroupData?.checkedItems?.length){
          //alert('selected '+ this.tempGroupData.checkedItems.map(a=> a.Id).join(','))
          //alert('unselected '+this.tempGroupData.uncheckedItems.map(a=> a.Id).join(','))
          selectedGroupIds = this.tempGroupData.checkedItems.map(a=> a.Id);
          this.getGroupMembers(selectedGroupIds);
        } else {
          this.getContacts();
        }
        //this.getContacts();
      }
      
    }, 
    //getgroupmembersmultiple
  },
  mounted() {
    this.isSelectAll = this.isSelectAllContacts;
    
    this.checkedMember = this.selectedMember;
    if (!this.isSelectAll && this.checkedMember.length){
      this.indeterminate = true;
    }
    //alert(this.checkedMember.join(','))
    this.selectedContacts = this.checkedMember.length;
    if (this.isSelectAll){
      this.uncheckedMember = this.selectedContactIds;
    }
    /* this.isSelectAll = this.isSelectAllContacts;
    this.checkedMember = this.selectedMember; */
    this.getGroupsAll();
    //alert('alert')
  },
};
</script>
<style scoped lang="scss">

.pagination {
  justify-content: center;
}
::v-deep .s2g-common-table {
  thead {
    tr {
      border-bottom: 2px solid #000;
    }
  }
}
</style>
