<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
      width="600"
      height="360"
    >
      <v-card>
        <v-card-title>
          <span class="dialog-title">Heard by...</span>
        </v-card-title>
        <button type="button"
            class="close-btn-icon"
            @click="onCloseWithoutSave"
          >
          <span class="outline">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
              </svg>

          </span>
          <span class="outline_hover">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
              </svg>
          </span>
          </button>

        <div class="gc-wrapper">
          <div class="gc-row">
            <div class="gc-line">{{ allGroups.length }} Groups</div>
            <div class="gc-line" v-for="group, in allGroups" :key="group.Id">
                <component index :is="group.Icon"></component> {{group.Name}} ({{ group.Member_Count }})
            </div>
          </div>

          <div class="gc-row">
            <div class="gc-line">{{ allContacts.length }} Contacts</div>
            <div class="gc-line" v-for="contact, in allContacts" :key="contact.UserID">
                {{contact.FirstName}} {{ contact.FirstName }}
            </div>
          </div>
          </div>
       <!-- <v-card-actions>
           <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            class="close-btn text-btn btn"
            variant="text"
            @click="onCloseWithoutSave"
          >
            Cancel
          </v-btn>
          <v-btn
            :ripple="false"
            color="blue-darken-1"
            variant="text"
            :disabled="!enableSaveButton"
            @click="onSaveSettings"
          >
            Save
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    
</template>

<script>
import { ref, computed } from 'vue';
import CompanyIcon from "@/components/icons/Company";
import GroupIcon from "@/components/icons/Group";
import EventIcon from "@/components/icons/Event";
import PropertyIcon from "@/components/icons/Property";
import AreaIcon from "@/components/icons/Area";
import TeamIcon from "@/components/icons/MemberIcon";
import ProjectIcon from "@/components/icons/Project";
import LocationIcon from "@/components/icons/Location";

export default {
components: {
    CompanyIcon,
    GroupIcon,
    EventIcon,
    ProjectIcon,
    PropertyIcon,
    LocationIcon,
    TeamIcon,
    AreaIcon,
},
props: {
  showAll: Boolean,
  messageReceivers: Object
},
data() {
  return {
    enableSaveButton: true,
    
  };
},
computed: {
  dialog: {
    get() {
      return this.showAll;
    },
    set(value) {
      this.$emit('closed', value);
    }
  },
  allGroups: {
    get(){ return this.messageReceivers.groupObjs;},
    set(){}
  },
  allContacts: {
    get(){ return this.messageReceivers.contactObjs;},
    set(){}
  }
},
methods: {
  
  onSaveSettings() {
    //this.$emit('group-selection-saved', this.data);
    this.dialog = false;
  },
  onCloseWithoutSave() {
    this.dialog = false;
  }
}
};
</script>


<style lang="scss" scoped>
.v-card .v-card-title {
    padding: 24px 0 16px 24px;
    line-height: 1;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
}
 .gc-wrapper {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  padding: 8px 24px 16px;
  height: 320px;
  overflow: auto;
  color: #828282;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
 }
 .gc-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.gc-line {
    display: flex;
    align-items: center;
    height: 24px;
    gap: 19px;
}
  
</style>