<template>
  <div class="hovered-btn">
    <svg class="default" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C13.3431 12 12 13.3431 12 15C12 16.6569 13.3431 18 15 18Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4 18C22.1277 18.6171 22.2583 19.3378 22.73 19.82L22.79 19.88C23.1656 20.2551 23.3766 20.7642 23.3766 21.295C23.3766 21.8258 23.1656 22.3349 22.79 22.71C22.4149 23.0856 21.9058 23.2966 21.375 23.2966C20.8442 23.2966 20.3351 23.0856 19.96 22.71L19.9 22.65C19.4178 22.1783 18.6971 22.0477 18.08 22.32C17.4755 22.5791 17.0826 23.1724 17.08 23.83V24C17.08 25.1046 16.1846 26 15.08 26C13.9754 26 13.08 25.1046 13.08 24V23.91C13.0642 23.2327 12.6359 22.6339 12 22.4C11.3829 22.1277 10.6622 22.2583 10.18 22.73L10.12 22.79C9.74486 23.1656 9.23582 23.3766 8.705 23.3766C8.17418 23.3766 7.66514 23.1656 7.29 22.79C6.91445 22.4149 6.70343 21.9058 6.70343 21.375C6.70343 20.8442 6.91445 20.3351 7.29 19.96L7.35 19.9C7.82167 19.4178 7.95235 18.6971 7.68 18.08C7.42093 17.4755 6.82764 17.0826 6.17 17.08H6C4.89543 17.08 4 16.1846 4 15.08C4 13.9754 4.89543 13.08 6 13.08H6.09C6.76733 13.0642 7.36613 12.6359 7.6 12C7.87235 11.3829 7.74167 10.6622 7.27 10.18L7.21 10.12C6.83445 9.74486 6.62343 9.23582 6.62343 8.705C6.62343 8.17418 6.83445 7.66514 7.21 7.29C7.58514 6.91445 8.09418 6.70343 8.625 6.70343C9.15582 6.70343 9.66486 6.91445 10.04 7.29L10.1 7.35C10.5822 7.82167 11.3029 7.95235 11.92 7.68H12C12.6045 7.42093 12.9974 6.82764 13 6.17V6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6V6.09C17.0026 6.74764 17.3955 7.34093 18 7.6C18.6171 7.87235 19.3378 7.74167 19.82 7.27L19.88 7.21C20.2551 6.83445 20.7642 6.62343 21.295 6.62343C21.8258 6.62343 22.3349 6.83445 22.71 7.21C23.0856 7.58514 23.2966 8.09418 23.2966 8.625C23.2966 9.15582 23.0856 9.66486 22.71 10.04L22.65 10.1C22.1783 10.5822 22.0477 11.3029 22.32 11.92V12C22.5791 12.6045 23.1724 12.9974 23.83 13H24C25.1046 13 26 13.8954 26 15C26 16.1046 25.1046 17 24 17H23.91C23.2524 17.0026 22.6591 17.3955 22.4 18Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg class="hovered" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0H20C25.5228 0 30 4.47715 30 10V20C30 25.5228 25.5228 30 20 30H10C4.47715 30 0 25.5228 0 20V10Z" fill="#E0E0E0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C13.3431 12 12 13.3431 12 15C12 16.6569 13.3431 18 15 18Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4 18C22.1277 18.6171 22.2583 19.3378 22.73 19.82L22.79 19.88C23.1656 20.2551 23.3766 20.7642 23.3766 21.295C23.3766 21.8258 23.1656 22.3349 22.79 22.71C22.4149 23.0856 21.9058 23.2966 21.375 23.2966C20.8442 23.2966 20.3351 23.0856 19.96 22.71L19.9 22.65C19.4178 22.1783 18.6971 22.0477 18.08 22.32C17.4755 22.5791 17.0826 23.1724 17.08 23.83V24C17.08 25.1046 16.1846 26 15.08 26C13.9754 26 13.08 25.1046 13.08 24V23.91C13.0642 23.2327 12.6359 22.6339 12 22.4C11.3829 22.1277 10.6622 22.2583 10.18 22.73L10.12 22.79C9.74486 23.1656 9.23582 23.3766 8.705 23.3766C8.17418 23.3766 7.66514 23.1656 7.29 22.79C6.91445 22.4149 6.70343 21.9058 6.70343 21.375C6.70343 20.8442 6.91445 20.3351 7.29 19.96L7.35 19.9C7.82167 19.4178 7.95235 18.6971 7.68 18.08C7.42093 17.4755 6.82764 17.0826 6.17 17.08H6C4.89543 17.08 4 16.1846 4 15.08C4 13.9754 4.89543 13.08 6 13.08H6.09C6.76733 13.0642 7.36613 12.6359 7.6 12C7.87235 11.3829 7.74167 10.6622 7.27 10.18L7.21 10.12C6.83445 9.74486 6.62343 9.23582 6.62343 8.705C6.62343 8.17418 6.83445 7.66514 7.21 7.29C7.58514 6.91445 8.09418 6.70343 8.625 6.70343C9.15582 6.70343 9.66486 6.91445 10.04 7.29L10.1 7.35C10.5822 7.82167 11.3029 7.95235 11.92 7.68H12C12.6045 7.42093 12.9974 6.82764 13 6.17V6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6V6.09C17.0026 6.74764 17.3955 7.34093 18 7.6C18.6171 7.87235 19.3378 7.74167 19.82 7.27L19.88 7.21C20.2551 6.83445 20.7642 6.62343 21.295 6.62343C21.8258 6.62343 22.3349 6.83445 22.71 7.21C23.0856 7.58514 23.2966 8.09418 23.2966 8.625C23.2966 9.15582 23.0856 9.66486 22.71 10.04L22.65 10.1C22.1783 10.5822 22.0477 11.3029 22.32 11.92V12C22.5791 12.6045 23.1724 12.9974 23.83 13H24C25.1046 13 26 13.8954 26 15C26 16.1046 25.1046 17 24 17H23.91C23.2524 17.0026 22.6591 17.3955 22.4 18Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>