<template>
        
        <v-menu :disabled="disabled" v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props }">
                <div v-bind="props">
                    
                    <span class="dropdown_text_container"> <CaretIcon :variant="isWhite?'white':''" /></span>
                    <!-- <span><v-icon style="float:left; margin-top:-5px" color="#7B8794">mdi-chevron-down</v-icon> {{value}}</span> -->
                </div>
                <!-- <v-btn v-bind:style = " disabled ? 'cursor:context-menu' : '' " class="form-control dropdown-btn" v-bind="props"  :ripple="false" plain>
                    <v-icon right>mdi-chevron-down</v-icon>
                    {{ selectedLabel }} {{selectedLabel !=  "Region Free" ? selectedLabel == "100 m" || selectedLabel == "200 m" || selectedLabel == "500 m" ? '' : itemUnit : ''}}
                    
                </v-btn> -->
            </template>
            
            <v-list>
                <template v-for="(item, index) in items"  :key="item.ms || index"  >
                    <v-list-item  :value="item.value || item.label" v-if="!item.show" @click="handleItemClick(item)">
                        <v-list-item-title><template v-if="item.isIcon">
                                <span class="mr-4"><component :is="item.icon"></component></span>
                            </template>{{ item.label }} {{item.value !=  "0" ? item.label == "100 m" || item.label == "200 m" || item.label == "500 m" ? '' : itemUnit : ''}}</v-list-item-title>
                    </v-list-item>
                </template>
                <!-- <v-list-item v-for="(item, index) in items" :value="item.value || item.label" :key="item.ms || index"  @click="handleItemClick(item)">
                  
                    <v-list-item-title>{{ item.label }} {{item.value !=  "0" ? item.label == "100 m" || item.label == "200 m" || item.label == "500 m" ? '' : itemUnit : ''}}</v-list-item-title>
                </v-list-item> -->
            </v-list>
        </v-menu>
  
</template>
  
  <script>
    //import { ref, defineProps, defineEmits, watch, computed } from 'vue';
    import CaretIcon from '@/components/icons/CaretIcon';
    import GroupIcon from "@/components/icons/Group";
    import EventIcon from "@/components/icons/Event";
    import PropertyIcon from "@/components/icons/Property";
    import AreaIcon from "@/components/icons/Area";
    import TeamIcon from "@/components/icons/MemberIcon";
    import ProjectIcon from "@/components/icons/Project";
    import LocationIcon from "@/components/icons/Location";
    import CompanyIcon from "@/components/icons/Company";

    
    export default {
        components: { CaretIcon, CompanyIcon ,GroupIcon,EventIcon,PropertyIcon, AreaIcon, TeamIcon,ProjectIcon, LocationIcon
        },
        props: ['value', 'unit', 'items', 'disabled','isWhite'],
        data(){
            return{
                menu: false,
                selectedLabel: this.value,
                selectedItem: this.value,
                itemUnit: this.unit
            }
        }, 
        mounted(){
            /* this.selectedLabel =  this.value;
            this.selectedItem = this.value;
            this.itemUnit = this.unit; */
        },
        methods:{
            handleItemClick(item){
                this.selectedLabel = item.label;
                this.selectedItem = item.value;
                this.menu = false; 
                //emits('item-selected', item);
                this.$emit('item-selected', item);
            }
        },
        watch :{
            value: function (newVal, oldVal) {
                this.selectedItem = newVal;
                this.selectedLabel = newVal;
            }
        }
    }
    /* 
    let props = defineProps(['value', 'unit', 'items', 'disabled','isWhite']);
    let emits = defineEmits(['item-selected']);
    let menu = ref(false);
    let selectedLabel = ref(props.value);
    let selectedItem = ref(props.value);
    let itemUnit = ref(props.unit);
    let items = ref(props.items);
    let disabled = ref(props.disabled);

    let handleItemClick = function(item) {
                selectedLabel.value = item.label;
                selectedItem.value = item.value;
                menu.value = false; 
                emits('item-selected', item);
        }
    
    watch(
        ()=>props.value, newVal => {
            selectedItem.value = newVal;
            selectedLabel.value = newVal;
        }
    ) */
  </script>
<style lang="scss">
    .dropdown_text_container {
        display: flex;
        align-items: center;
    }
    .dropdown-btn{
        box-shadow: none !important;
        width: 100%;
        text-align: left !important;
        &::before{
            display: none !important;
        }
        &:hover, &:focus{
            background: #fff;
            box-shadow: none;
        }
        
        .v-btn__content{
            text-align: left !important;
            justify-content: space-between !important;
            align-items: center !important;
            color:  #828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important; 
            text-transform: none !important;
            
            .v-icon{
                font-size: 26px !important;
                color: #7B8794 !important;
            }
        }
        .v-list-item{
            color:#828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important;
        }
    }
</style>