<template>
    
      <v-dialog
        v-model="dialog"
        persistent
        class="account-settings-modal"
      >
        <v-card>
          <v-card-title>
            <span class="dialog-title">Account Settings</span>
          </v-card-title>
            <v-card-text>
              <ul class="settings-list">
                <li>
                  <SwitchLg v-model="canShareContacts" /> 
                  <h5>Operators can share Contacts</h5>
                  <div :class="{ 'orange-highlight': canShareContacts }" class="message">- Contacts from other Operators are read only.<br/>
                    - Contacts are also shared from sharing Groups.</div>
                </li>
                <li>
                  <SwitchLg v-model="canViewAllMessages" /> 
                  <h5>Operators can view all Messages</h5>
                  <div :class="{ 'orange-highlight': canViewAllMessages }" class="message">Operators can know what has been communicated by other Operators to Groups and Contacts.</div>
                </li>
              </ul>
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              class="close-btn text-btn btn"
              variant="text"
              @click="onCloseWithoutSave"
            >
              Close
            </v-btn>
            <v-btn
              :ripple="false"
              color="blue-darken-1"
              variant="text"
              :disabled="!enableSaveButton"
              @click="onSaveSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
  </template>

<script setup>
    import { ref, defineEmits, defineProps, computed, watch, onMounted } from 'vue';
    import SwitchLg from '@/components/common/SwitchLg.vue';
    let enableSaveButton = ref(true);
    const canShareContacts = ref(false);
    const canViewAllMessages = ref(false);

    let props = defineProps({
        showSettings:Boolean,
    })

    const emits = defineEmits(['closed', 'setting-saved']);
    
    let dialog = computed({
            get() {
                return props.showSettings;
            },
            set(value) {emits('closed', value);}
    })
        
    let onCloseWithoutSave = function(){
        dialog.value = false;
    }

</script>


<style lang="scss">
   .account-settings-modal {
    & .v-overlay__content {
      width: 900px;
      & .v-card {
        height: 458px;
        & .v-card-text {
          padding: 0 83px 0 63px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    & .v-card-title {
      line-height: 1 !important;
      padding: 22px 30px 10px !important;
    }
    & .v-card-actions {
      margin-top: 0;
    }
   }

   .settings-list {
    display: flex;
    flex-direction: column;
    gap: 38px;
    padding: 0;
    margin: 0;
    & li {
      display: flex;
      align-items: center;
      gap: 17px;
      & h5 {
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
      }
      & .message {
        padding: 10px 20px 10px 15px;
        border-radius: 10px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        background-color: #344D7A;
        margin-left: auto;
        width: 400px;
        transition: 0.2s all ease;
        &.orange-highlight {
          background-color: #FFE3B7;
          color: black;
        }
      }
    }
   }
</style>