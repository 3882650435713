<template>
   <v-row>
 
    <template v-if="isGroup">
<!--       <div class="position-relative">
        <div class="w-100 position-absolute info_panel_msg">
          <div v-if="showInfo">
            <InfoPanel :isClose="false" :panelClass="'warning_panel'">
            <p><strong>Attention: </strong>At least 1 Group must be selected.</p>
            </InfoPanel>
          </div>
          <div class="info-message" v-if="selectedItem > 0 || isSelectAll">
          <strong>{{selectedItem }} Selected: </strong> {{ selectedItem < 2 ? 'Group' : 'Groups' }} will hear this geofence message.
          </div>
        </div>
        
      </div> -->
     
      <div class="group-list-table">
      <S2gTable
        :indeterminate="indeterminate"
        :items="groups"
        :headers="groupListHeader"
        :isWhiteIcons="false"
        :showCount="true"
        :totalItems="`${activeGroupsCount}`"
        :totalCountText="'Groups'"
        :selectAllCbDisabled="this.groups.length < 2"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
        ]"
        @search="search"
        @column-filter="handleFilter"
        @toggle-row-select="toggleRowChecked"
        @refresh="reloadTableData"
        @select-all="selectAll"
        @close-search="onCloseSearch"
        @open-search="onOpenSearch"
        :isSelectGlobalCheckBox="isSelectAll"
        :page="(groupPagination.currentPage - 1) * groupPagination.itemsPerPage"
        :disabledColumn="groups.length <2 && !groupFilter.Group_Type_Id ? {'first_col':'true', 'col':['Group_Type_Id', 'total_member']} : {}"
      >
        <template v-slot:table_body="{ item }">
          <td>
            <span>{{ item.item.Name }}</span>
          </td>
          <td>
            <div class="filter-cell">
              <span class="svg_icon">
                <component :is="item.item.Icon"></component>
              </span>
              {{ item.item.Group_Type }}
            </div>
            
          </td>
          <td align="right">{{ item.item.Member_Count }}</td>
        </template>
      </S2gTable>
      </div>
      <v-row class="mt-0">
        <v-col cols="12">
        <Paginaiton
          v-if="groupPagination.pager.totalPages > 1"
          :currentPage="groupPagination.currentPage"
          :totalItems="groupPagination.pager.totalItems"
          :itemsPerPage="groupPagination.itemsPerPage"
          :totalPages="groupPagination.pager.totalPages"
          @page-changed="changePage"
        />
      </v-col>
    </v-row>
    </template>

 
</v-row>
</template>
<script >
const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
import InfoPanel from "@/components/common/InfoPanel.vue";
import CompanyIcon from "@/components/icons/Company";
import GroupIcon from "@/components/icons/Group";
import EventIcon from "@/components/icons/Event";
import PropertyIcon from "@/components/icons/Property";
import AreaIcon from "@/components/icons/Area";
import TeamIcon from "@/components/icons/MemberIcon";
import ProjectIcon from "@/components/icons/Project";
import LocationIcon from "@/components/icons/Location";
import S2gTable from "@/components/common/S2gTable.vue";
import Paginaiton from "@/components/common/Paginaiton.vue";
import Confirmation from "@/components/modals/Confirmation.vue";

import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";

export default {
  props: {
    groupTypesList: [],
    isSelectAllGroups: Boolean,
    selectedGroupsIds:[]
  },
  components: {
    S2gTable,
    Paginaiton,
    Confirmation,
    CompanyIcon,
    GroupIcon,
    EventIcon,
    ProjectIcon,
    PropertyIcon,
    LocationIcon,
    TeamIcon,
    AreaIcon,
    InfoPanel,
  },
  data() {
    return {
      groupListHeader: [
        { key: "name", title: "Name", width: "60%" },
        {
          key: "Group_Type_Id",
          title: "Type",
          width: "36%",
          isFilter: true,
          filterItems: [],
        },
        { key: "total_member", title: "" },
      ],
      groups: [],
      group: {},
      isGroup: false,
      confirmDialog: false,
      indeterminate: false,
      isSelectAll: false,
      showInfo: false,
      searchText: "",
      selectedItem: 0,
      token: localStorage.getItem("tknds") || "",
      groupPagination: {
        currentPage: 1,
        pager: { totalPages: 0, totalItems: 0 },
        itemsPerPage: 10,
      },
      groupFilter: { Group_Type_Id: null,status:undefined },
      uncheckedItems: [],
      checkedItems: [],
      activeGroupsCount : 0
    };
  },
  methods: {
    onCloseSearch(){
      this.$emit("search-open", false);
    },
    onOpenSearch(){
      this.$emit("search-open", true);
    },
    getGroups() {
      let url =
        API_URL +
        "groups/?page=" +
        this.groupPagination.currentPage +
        "&perpage=" +
        this.groupPagination.itemsPerPage +
        "&search=" +
        this.searchText;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      if (this.groupFilter.Group_Type_Id) {
        url += "&Group_Type_Id=" + this.groupFilter.Group_Type_Id;
      }
      if (this.groupFilter.status) {
        url += "&status=" + this.groupFilter.status;
      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          } else {
            
            this.groups = result.data?.map((e) => {
              
              if (e.Status == "active"){
                e.checked = manageRowDefaultSelection(
                  this.isSelectAll,
                  e,
                  this.checkedItems,
                  this.uncheckedItems,
                  "Id"
                );
              }
              e.disable_checkbox = (e.Status == "inactive" || (result.data.length < 2 && this.groupPagination.currentPage == 1 && !this.groupFilter.Group_Type_Id));
              e.class = (e.Status == "inactive" || (result.data.length < 2 && this.groupPagination.currentPage == 1 && !this.groupFilter.Group_Type_Id)) ? "" : "hover_item";
              e.checkdClass = e.checked ? "member_selected" : "";
              if (this.isSelectAll && !this.indeterminate && !this.checkedItems.some(obj => obj.Id === e.Id)){
                  this.checkedItems.push(e);
              }
              return e;
            });

            if (this.groups.length > 0) {
              this.isGroup = true;
            } 
            this.groupPagination.pager = result.pager;
            this.activeGroupsCount = result.activegroupcount;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    ,
    reloadTableData(e) {
      /* this.groups = [];
      this.group = {};
      this.confirmDialog = false;
      this.indeterminate = false;
      this.isSelectAll = false;
      this.showInfo = true;
      this.searchText = "";
      this.selectedItem = 0;
      this.groupFilter = { Group_Type_Id: null };
      this.uncheckedItems = [];
      this.checkedItems = [];
      this.getGroups(); */
      //this.showInfo = true;
      this.searchText = "";
      this.groupFilter = { Group_Type_Id: null };
      this.isSelectAll = this.isSelectAllGroups;
      if (!this.isSelectAll){
        this.indeterminate = true;
      }
      this.checkedItems = this.selectedGroupsIds.filter(item => item.Status === "active");
      this.selectedItem = this.checkedItems.length; 
      if (this.isSelectAll){
        this.uncheckedItems = this.selectedGroupsIds.filter(item => item.Status !== "active");
      }
      this.getGroups();
      
      this.groupListHeader = this.groupListHeader.map((e) => {
        if (e.isFilter && e.key == "Group_Type_Id") {
          e.filterItems = this.groupTypesList;
        }
        return e;
      });
    },
    toggleRowChecked(e) {
      const event = e.e;
      const u = manageSingleChecked(
        event,
        e.item,
        "Id",
        this.groups,
        this.selectedItem,
        this.isSelectAll,
        this.activeGroupsCount
      );
      this.selectedItem = u.selectedCounts;
      
      this.indeterminate = u.indeterminate;
      
      this.isSelectAll = u.isSelectAll;
      if (this.selectedItem == 0) {
        this.indeterminate = false;
      }
      this.manageClass()
      manageSelectedItems(event, e.item, "Id", this.checkedItems);
      manageUnSelectedItems(event, e.item, "Id", this.uncheckedItems);
      this.manageGlobalSelection();
      
    },
    manageClass(){
       this.groups = this.groups.map((e) => {
       // e.class = e.Status == "inactive" ? "disabled" : "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        return e;
      }); 
    },
    selectAll(event) {
      //this.checkedMember = [];
      this.uncheckedMembers = [];
      this.isSelectAll = event.target.checked;
      this.indeterminate = false;
      
      this.checkedItems = this.groups.map((e) => {
        e.checked = (e.Status == "active") ? event.target.checked : false;
        return e;
      });
      
      this.manageClass()
      if (this.isSelectAll) {
        let countActive = 0;
        this.groups.map(e => (e.Status === "active" ? countActive++ : null, e));
        this.selectedItem = this.activeGroupsCount;

      } else {
        this.checkedItems = [];
        this.selectedItem = 0;
      }
      
    },
    manageGlobalSelection() {
      //const checkedCount = this.users.filter((e) => e.checked).length;
      if (this.selectedItem > 0) {
        if (this.selectedItem == this.activeGroupsCount) {
          this.isSelectAll = true;
          this.selectedMessages = this.activeGroupsCount;
          this.indeterminate = false;
        } else if (!this.isSelectAll) {
          this.indeterminate = true;
        }
      } else {
        this.indeterminate = false;
        this.isSelectAll = false;
      }
    },
    search(val) {
      this.searchText = val;
      this.getGroups();
    },
    handleFilter(e) {
      console.log(e);
      try{
      const event = e.e;
      if (!e.column) {
        if (event.value == "all") {
          this.selectAll({ target: { checked: true } });
        } else {
          this.selectAll({ target: { checked: false } });
        }
      } else {
        this.groupPagination = {
          currentPage: 1,
          pager: { totalPages: 0, totalItems: 0 },
          itemsPerPage: 10,
        };
        console.log(e.column.key)
        this.groupFilter[e.column.key] = e.e.value;
        console.log('000', this.groupFilter)
        this.getGroups();
      }
       }catch(e){
        console.log(e)
      }
    },
    changePage(page) {
      this.groupPagination.currentPage = page;
      this.getGroups();
    }
  },
  watch: {
    selectedItem: function (newVal, oldVal) {
        //this.showInfo = this.checkedItems.length < 1;
        this.showInfo = this.selectedItem < 1;
        this.$emit('groups-selected', {checkedItems: this.checkedItems, isSelectAll: this.isSelectAll, uncheckedItems : this.uncheckedItems, allGroups:this.groups, selectedItem:this.selectedItem});      
    },
    isSelectAll: function (newVal, oldVal) {
        //this.showInfo = this.checkedItems.length < 1;
        this.showInfo = this.selectedItem < 1;
        this.$emit('groups-selected', {checkedItems: this.checkedItems, isSelectAll: this.isSelectAll,  uncheckedItems : this.uncheckedItems, allGroups:this.groups, selectedItem:this.selectedItem});      
    }
  
  },

  mounted() {
    this.isSelectAll = this.isSelectAllGroups;
    if (!this.isSelectAll){
      this.indeterminate = true;
    }
    this.checkedItems = this.selectedGroupsIds.filter(item => item.Status === "active");
    this.selectedItem = this.checkedItems.length;
    if (this.isSelectAll){
      this.uncheckedItems = this.selectedGroupsIds.filter(item => item.Status !== "active");
    }
    this.getGroups();
    
    this.groupListHeader = this.groupListHeader.map((e) => {
      if (e.isFilter && e.key == "Group_Type_Id") {
        e.filterItems = this.groupTypesList;
      }
      return e;
    });
    console.log('----', this.groupListHeader)
  },
};
</script>
<style lang="scss">
 .group-list-table {
  width: 100%;
 }
</style>

 <style scoped lang="scss">
 .pagination {
  justify-content: center;
}
 .info_panel_msg {
  & strong {
    font-weight: 500 !important;
  }
 }
 .info-message {
  border-radius: 10px;
    font-weight: 400;
    color: #000;
    width: 100%;
    height: 50px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #F2F2F2;
    & strong {
    font-weight: 500 !important;
  }
 }
::v-deep {
  .danger_text {
    td {
      color: #b10000;
      &:not(:first-child) {
        .svg_icon{
        svg {
          path,
          rect {
            stroke: #b10000;
          }
        }
        }
      }
    }
  }
  .warning_panel {
    background: #F0D3D3;
  }
}
 
.pd-14{
  padding-left: 10px;
}
.switch-icon-btn{
  line-height: 0;
}
 
</style>